<mat-progress-bar mode="indeterminate" class="fixed-top" [class.d-none]="!show"></mat-progress-bar>
<!-- <div class="site-loader" [class.d-none]="!show">
  <div class=" d-flex h-100 modal-backdrop">
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
    <img class="mx-auto justify-content-center align-self-center" src="https://bullymake.com/wp-content/plugins/better-user-management/public/img/my-account-loader.gif" alt="">
  </div>
</div> -->
<div id="topbar" class="" style="background: #49BD98">
    <div class="container text-center py-1">
      <!-- <p class="mb-0 text-light text-uppercase">First Box for $19 on Multi-Month Plans! Code: <strong class="text-darka fw-bold">BOX19</strong></p> -->
	  <a routerLink="/subscribe" class="mb-0 text-light text-uppercase text-decoration-none">HOLIDAY SALE: GET UP TO 50% OFF + <strong>FREE GIFT! </strong> <span class="" style="text-decoration: underline;">Redeem Now!</span></a>
    </div>
</div>

<div id="secondary-topbar" class="" style="background: #49BD98">
    <div class="container text-center py-1">
      <p class="mb-0 text-light text-uppercase">20% off Your First Box with code: <strong class="text-darka fw-bold">20BULLY</strong></p>
    </div>
</div>

<!-- new navbar -->
<nav class="new-navbar navbar navbar-expand-lg navbar-dark">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/logo-bullymake-665b0bd5de8cc.webp" alt="bullymake logo">
    </a>

    <a class="btn btn-sm btn-filled btn-white shadow d-inline-block d-lg-none" [routerLink]="['/subscribe']">Join Now</a>



    <button id="navbar-toggler" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="primary-menu navbar-nav mr-auto">
        <li class="nav-item"><a class="nav-link sr-only" (click)="toggleMenu();" [routerLink]="['/accessibility']">Click to view our Accessibility Statement</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/subscribe']">Join Now</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/in-the-box']">What's in the Box</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/faq']">Faq</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/past-boxes']">Past Boxes</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/reviews']">Reviews</a></li>
      </ul><!-- END .primary-menu -->

      <ul class="secondary-menu navbar-nav">
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/how-this-dog-company-changed-the-game-for-power-chewing-dogs']">Why Bullymake?</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/my-account']">My Account</a></li>
        <li class="nav-item"><a class="nav-link" (click)="toggleMenu();" [routerLink]="['/contact-us']">Contact Us</a></li>
        <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/checkout']">Checkout</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/signin']">Signin</a></li> -->
      </ul><!-- END .secondary-menu -->
    </div><!-- END .collapse -->

  </div><!-- END .container -->
</nav>
